import React from 'react'
import PropTypes from 'prop-types'

const invalidCitations = [
  'not specified',
  'not specified.',
  'not specified.',
  'not specified in the document',
  'not specified in the document',
  'not specified in the document.',
]

const isValidCitation = (citation) =>
  citation && !invalidCitations.includes(citation?.toLowerCase())

export const CitationDisplay = ({ secCitation, ppmCitation, answerNarrative }) => (
  <>
    {isValidCitation(ppmCitation) && (
      <>
        <br />
        <div>
          <b>PPM:</b> {ppmCitation}
        </div>
      </>
    )}
    {isValidCitation(secCitation) && (
      <>
        <br />
        <div>
          <b>SEC:</b> {secCitation}
        </div>
      </>
    )}
    {isValidCitation(answerNarrative) && (
      <>
        <br />
        <div>
          <b>Answer Narrative:</b> {answerNarrative}
        </div>
      </>
    )}
  </>
)

CitationDisplay.propTypes = {
  answerNarrative: PropTypes.string,
  ppmCitation: PropTypes.string,
  secCitation: PropTypes.string,
}
