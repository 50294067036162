import { INVESTOR_ACCREDITED, INVESTOR_NON_ACCREDITED } from 'const/types'

export * from './user-hooks'
export const fromServerData = (user) => ({
  ...user,
  _accredited: user.accredited ? INVESTOR_ACCREDITED : INVESTOR_NON_ACCREDITED,
})

export const toServerData = ({ _resident, _accredited, _isJoint, jointType, ...data }) => ({
  ...data,
  accredited: _accredited === INVESTOR_ACCREDITED,
})
