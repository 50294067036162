import './style.css'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { Form, SubmitButton } from 'ui/form'
import { Loader } from 'ui/data-loader/loader'
import { Checkbox } from 'ui/field'
import { Dialog } from 'ui/dialog'
import { useAlert } from 'ui/alert'
import { ExternalLink } from 'ui/link'

import { useLoader } from 'components/use-loader'
import { loadPDF, PdfPreview } from 'components/PDF'
import { useUser } from 'components/user'
import { usePortal } from 'components/portal-provider'

import { routes } from 'const/routes'
import { api } from 'api'
import { User, DOCUMENT_TYPE } from 'const/types'

const BASE_URL = process.env.NODE_ENV === 'production' ? location.origin : process.env.API_HOST

const getCRSUrlsFromUser = (user) => {
  const crsUuids = user?.additionalFormDocuments
    ?.map(({ type, uuid }) => (type === DOCUMENT_TYPE.CRS ? uuid : false))
    ?.filter(Boolean)

  return user?.additionalFormDocumentUrls
    ?.map((url) => (crsUuids.some((uuid) => url.includes(uuid)) ? BASE_URL + '/app/' + url : false))
    ?.filter(Boolean)
}
async function getPDFs(urls) {
  let pdfs = []
  for (let i = 0; i < urls?.length; i++) {
    const data = await loadPDF(urls[i])
    pdfs.push({ pdf: data, link: urls[i] })
  }
  return { pdfs }
}

const Preview = ({ pdfs }) => {
  return (
    <div className="crs-preview-wrapper">
      {pdfs?.map(({ pdf, link }) => (
        <PdfPreview key={pdf?.data} data={pdf?.data} link={link} />
      ))}
    </div>
  )
}

Preview.propTypes = {
  pdfs: PropTypes.arrayOf(PropTypes.shape({ data: PropTypes.string, link: PropTypes.string })),
}

export const CRSForm = ({ onSubmit, urls, name }) => {
  const loader = useLoader(getPDFs, urls)

  return (
    <Form onSubmit={onSubmit} className="crs-form" name={name}>
      <Loader {...loader}>
        <Preview />
      </Loader>
      <Checkbox
        required
        name="acknowledge"
        label="I certify that I have read and acknowledge the above form CRS."
      />
      <footer>
        <SubmitButton>Submit</SubmitButton>
      </footer>
    </Form>
  )
}

CRSForm.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  urls: PropTypes.arrayOf(PropTypes.string),
}

const CRSAlertMessage = 'Accepted successfully'

export const CRSFormDialogIdle = () => {
  const alerts = useAlert()
  const portal = usePortal()
  const location = useLocation()
  const user = useUser()

  const defaultOpen = useMemo(() => {
    if (!portal.additionalFormToShowAfterRegistration || !user) {
      return false
    }

    if (!portal?.additionalFormToShowInTheInvestmentBeginning) {
      return !user?.hasSignedCRS
    }

    const invest = routes.addInvestment.replace(/\//g, '')
    const clientSetPassword = routes.clientConfirm.replace(/\//g, '')
    const userInvite = routes.userInvite.replace(/\//g, '')
    const path = window.location.pathname.split('/')[1]
    const restrictedByRoutes = path === invest || path === clientSetPassword || path === userInvite

    return !restrictedByRoutes && !user?.hasSignedCRS
  }, [
    portal.additionalFormToShowAfterRegistration,
    portal?.additionalFormToShowInTheInvestmentBeginning,
    user,
  ])

  const [isOpen, setIsOpen] = useState(defaultOpen)

  useEffect(() => {
    if (!user || !portal.additionalFormToShowAfterRegistration) {
      isOpen && setIsOpen(false)
      return
    }
    const invest = routes.addInvestment.replace(/\//g, '')
    const clientSetPassword = routes.clientConfirm.replace(/\//g, '')
    const userInvite = routes.userInvite.replace(/\//g, '')
    const path = location.pathname.split('/')[1]
    const restrictedByRoutes =
      (portal?.additionalFormToShowInTheInvestmentBeginning && path === invest) ||
      path === clientSetPassword ||
      path === userInvite
    if (!restrictedByRoutes && !user?.hasSignedCRS) {
      setIsOpen(true)
    }
  }, [
    isOpen,
    location.pathname,
    portal.additionalFormToShowAfterRegistration,
    portal?.additionalFormToShowInTheInvestmentBeginning,
    user,
  ])

  const handleSubmit = useCallback(async () => {
    const data = { uuid: user?.uuid, signature: user?.name }
    await api.user.signCrsForm(data)
    const currentUser = await api.user.getAccount()
    if (!currentUser?.hasSignedCRS) {
      throw { error: 'Something Went Wrong Try Again' }
    }

    setIsOpen(false)
    alerts.success({
      message: CRSAlertMessage,
    })
  }, [alerts, user?.name, user?.uuid])

  const CRSFormsUrls = useMemo(() => getCRSUrlsFromUser(user), [user])

  return (
    isOpen && (
      <Dialog className="crs-form-dialog" title="Confirmation">
        <CRSForm onSubmit={handleSubmit} urls={CRSFormsUrls} name="idle_crs_form" />
      </Dialog>
    )
  )
}

CRSFormDialogIdle.propTypes = {}

export const CRSFormDialogInvestment = ({ isOpen, onSubmit, user }) => {
  const alerts = useAlert()
  const handleSubmit = useCallback(async () => {
    await onSubmit?.()
    alerts.success({
      message: CRSAlertMessage,
    })
  }, [alerts, onSubmit])

  const CRSFormsUrls = useMemo(() => getCRSUrlsFromUser(user), [user])

  return (
    isOpen && (
      <Dialog className="crs-form-dialog" title="Confirmation">
        <CRSForm onSubmit={handleSubmit} urls={CRSFormsUrls} name="investment_flow_crs_form" />
      </Dialog>
    )
  )
}

CRSFormDialogInvestment.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.bool,
  user: User,
}

export const UserCRSFormsWrapper = ({ user }) => {
  const CRSForms = useMemo(() => {
    // app/user/additional-form-documents/uuid/download with signed form instead of app/users/additional-form-documents/uuid/download without signed
    return getCRSUrlsFromUser(user)?.map((path) => path.replace('users', 'user'))
  }, [user])

  return (
    CRSForms?.length && (
      <div className="crs-forms-wrapper">
        <h4>CRS Forms:</h4>
        <ul>
          {CRSForms.map((link) => (
            <li key={link}>
              <ExternalLink href={link}>Click here to open</ExternalLink>
            </li>
          ))}
        </ul>
      </div>
    )
  )
}

UserCRSFormsWrapper.propTypes = {
  user: User,
}
