import PropTypes from 'prop-types'
import React from 'react'

import { ExternalLink } from 'ui/link'

import { authStorage, userStorage } from 'api/storage'
import { refreshToken } from 'api/config-client'

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64 = reader.result.split(',')[1]
      resolve(base64)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(file)
  })
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const loadPDF = async (url) => {
  const user = userStorage.value
  if (user && authStorage.isAccessTokenExpired()) {
    await refreshToken()
  }
  const response = await fetch(url, {
    headers: authStorage.getHeaders(),
  })
  const file = await response?.blob()

  return { data: URL.createObjectURL(file) }
}

export const PdfPreview = ({ data, link }) => {
  return isSafari ? (
    <iframe
      className="pdf-preview"
      src={data}
      title="mailchimp"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      height="100%"
    />
  ) : (
    <object className="pdf-preview" data={data}>
      <p>
        Not supported on your current browser/device. Please try a different browser/device
        {link && ` or use the link below`}.
      </p>
      <p>
        <ExternalLink href={link}>Click Here</ExternalLink>
      </p>
    </object>
  )
}

PdfPreview.propTypes = {
  data: PropTypes.string,
  link: PropTypes.string,
}
