import React, { useContext } from 'react'

import { DefaultErrorHandler } from 'ui/data-loader/loader'

import { api } from 'api'

import { useLoader } from '../use-loader'

export const PortalPagesContext = React.createContext()

const getInitialData = async () => {
  return await api.portalPages.list({ isUiContext: true })
}

export const PortalPagesProvider = ({ children }) => {
  const { error, pending, result } = useLoader(getInitialData)
  if (error) return <DefaultErrorHandler error={error?.error || error} />
  const sortedPages = result?.items.sort((a, b) => a.weight - b.weight) ?? []
  return (
    <PortalPagesContext.Provider value={{ pages: sortedPages, pending }}>
      {children}
    </PortalPagesContext.Provider>
  )
}

export const usePortalPages = () => {
  return useContext(PortalPagesContext)
}
