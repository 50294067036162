import { useEffect } from 'react'

import { useAlert } from 'ui/alert'

import { maintenanceStorage } from 'api/storage'

export const MaintenanceIdleAlert = () => {
  const alerts = useAlert()
  useEffect(() => {
    maintenanceStorage.addListener((maintenance) => {
      if (maintenance) {
        maintenanceStorage.value = null
        alerts.error('The site is currently undergoing maintenance.')
      }
    })
  }, []) //eslint-disable-line

  return null
}
